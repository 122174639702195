import React from 'react';
import './Section6.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Section6 = () => (
  <div className="section6" id="section6">
    <div className="section6-left-content">
      <h1>Looking for More Ways to Connect?</h1>
      <p>
        Visit our Contact Page for detailed information on how to reach our various offices and departments. <br />
        Whether you’re looking for specific department contacts, office locations, and more, for general inquiries you can submit your message from here. <br />
        Your information will be kept confidential and used solely for the purpose of responding to your inquiries.
      </p>
    </div>

    <div className="section6-right-content">
      <div className="section6-text-box">
        <input type="text" placeholder="Recent Project Completions" />
      </div>
      <div className="section6-text-box">
        <input type="text" placeholder="Industry Insights" />
      </div>
      <div className="section6-text-box large-box">
        <input type="text" placeholder="Events" />
      </div>

      <div className="section6-action-container">
        <div className=" section6-icons">
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a href="https://www.instagram.com/leo_engineering_consultancy?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.facebook.com/share/AV77PGDkxVe3Zp3R/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
        <button className="section6-submit-button">Submit</button>
      </div>
    </div>
  </div>
);

export default Section6;
