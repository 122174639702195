import React, { useState, useEffect } from 'react';
import './Section3.css';

const newsHeadlines = [
  /*Cyrene Declaration - A sustainable region */
  { image: '/Correctthumbnail_401.jpg', title: 'A Glimpse into the Past', subtitle: 'Exploring our heritage, a selection of project photos' },
  { image: '/CorrectSectionThumbnail402.jpg', title: 'New generations of Libyan architects', subtitle: 'Shaping the Future of Design in Libya' },
  { image: '/CorrectSectionThumbnail403.jpg', title: 'Engineers Syndicate Conference', subtitle: 'Benghazi Construction Exhibition' },
  { image: '/CorrectSectionThumbnail404.jpg', title: 'Shahat Declaration a Sustainable Region', subtitle: 'Pioneering Sustainable Urban Development in Libyas Green Heart' },

];

const galleryImages = [
  { src: '/CorrectSection401.jpg', title: 'Cyrene Declaration', body: 'A sustainable region.' },
  { src: '/CorrectSection4002.jpg', title: 'New generations of Libyan architects', body: 'Shaping the Future of Design in Libya' },
  { src: '/CorrectSection4003.jpg', title: 'Engineers Syndicate Conference', body: 'Benghazi Construction Exhibition' },
  { src: '/CorrectSection4004.jpg', title: 'Shahat Declared a Sustainable Region', body: 'Pioneering Sustainable Urban Development in Libyas Green Heart' },

];

const Section3 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleNewsItemClick = (index) => {
    setCurrentIndex(index);
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  const currentImage = galleryImages[currentIndex];

  return (
    <div className="section3" id="section3">
      <div className="section3-content-wrapper"> {/* New wrapper for white background */}
        <div className="section3-content-container">
          <div className="section3-left-content">
            <div className="section3-newsletter-title">
              <h1>News & Insights</h1>
            </div>
            <div className="section3-news-headlines">
              {newsHeadlines.map((item, index) => (
                <div
                  className="section3-news-item"
                  key={index}
                  onClick={() => handleNewsItemClick(index)}
                >
                  <img src={item.image} alt={item.title} className="section3-news-image" />
                  <div className="section3-news-text">
                    <h3 className="section3-news-title">{item.title}</h3>
                    <p className="section3-news-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="section3-gallery">
            <img src={currentImage.src} alt={`Gallery ${currentIndex + 1}`} className="section3-gallery-image" />
            <div className="section3-banner">
              <div className="section3-banner-content">
                <h2 className="section3-banner-title">{currentImage.title}</h2>
                <p className="section3-banner-body">{currentImage.body}</p>
              </div>
            </div>
            <div className="section3-progress-indicators">
              {galleryImages.map((_, index) => (
                <div
                  key={index}
                  className={`section3-indicator ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => handleIndicatorClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Horizontal line for Section 3 */}
      {/*<img src="/line.png" alt="divider" className="section3-line" />*/}
    </div>
  );
};

export default Section3;
